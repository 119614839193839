<template>
  <div>
    <v-stepper-step
      :complete="pos > step"
      :step="step"
    >
    <v-layout wrap style="width:100%;">
      <v-flex xs7>
        {{ $t(`booking.activities.steps.${item.title}`) }}
        <br/>
        <small v-if="item.subtitle">
          {{ $t(`booking.activities.steps.${item.subtitle}`) }}
        </small>
      </v-flex>
      <v-flex xs5 style="text-align:right;">
        <v-btn
          v-if="pos===step && !last"
          text
          @click="onChangeStep(1)"
          small
        >
          omitir
        </v-btn>
      </v-flex>
    </v-layout>
    </v-stepper-step>

    <v-stepper-content :step="step" >
      <v-card
        v-if="item.form === 'scheduleType' && activity"
        color="grey lighten-1"
        class="mb-12"
        min-height="100px"
      >
        <v-layout wrap style="padding-bottom:20px">
          <v-flex xs12 
            style="padding:20px 20px 20px 20px"
          >
            <translate
              v-model="activity.Name"
              :languages="languages"
              :label="$t('booking.title', locale)"
              @input="handleChangeIsModified"
              type="text"
            />
          </v-flex>
          <v-flex xs12
            v-if="!scheduleTypes"
            style="padding: 0 20px 0px 20px"
          >
            <center>
              <v-btn-toggle
                v-model="activity.ScheduleType"
                color="primary"
                group
              >
                <v-btn value="specific">
                  {{ $t('booking.schedules.specific', locale) }}
                </v-btn>
                <v-btn value="recurrence">
                  {{ $t('booking.schedules.recurrence', locale) }}
                </v-btn>
              </v-btn-toggle>
            </center>
          </v-flex>
    <v-flex xs12
      style="margin-top:20px;padding: 10px 20px 0px 20px;border-top: 1px solid #CCCCCC80;"
    >
      <small>{{ $t('booking.forceOrder', locale) }} :</small>
    </v-flex>
    <v-flex xs12>
      <custom-fields-info
        :workspaceID="workspaceID"
        :activityID="editedItem.ID"
        :locale="locale"
        :languages="languages"
        :onChange="handleChangeCustomFieldsInfo"
      />
    </v-flex>
    <v-flex xs6
      style="padding: 0px 20px 20px 20px"
    >
      <v-checkbox
        v-model="editedItem.ForceFirst"
        :label="$t('booking.forceFirst', locale)"
        @click="editedItem.ForceLast = editedItem.ForceFirst ? false : editedItem.ForceLast"
        hide-details
      />
    </v-flex>
    <v-flex xs6
      style="padding: 0px 20px 20px 20px"
    >
      <v-checkbox
        v-model="editedItem.ForceLast"
        :label="$t('booking.forceLast', locale)"
        @click="editedItem.ForceFirst = editedItem.ForceLast ? false : editedItem.ForceFirst"
        hide-details
      />
    </v-flex>
<v-flex xs12 style="padding:0px 20px 0 20px;border-top: 1px solid #CCCCCC80;"
  v-if="workspaceAllowReservation"
>
            <v-checkbox
              v-model="activity.AllowReservations"
              :label="$t('booking.allowReservations', locale)"
              hide-details
            />
            <v-checkbox
              v-model="activity.AllowReservationsEventStart"
              :label="$t('booking.allowReservationsEventStart', locale)"
              hide-details
            />
          </v-flex>
          <template
            v-if="activity.AllowReservations"
          >
          <template style="padding: 0 20px">
            <v-flex xs12 style="padding: 20px">
              <h3>
                {{ $t('booking.activities.timeLimit', locale) }}
              </h3>
            </v-flex>
            <v-flex xs6 style="padding: 0 10px 0 20px">
              <custom-text-field-number
                v-model="activity.TimeLimitFrom"
                :label="$t('booking.activities.timeLimitFrom', locale)"
              />
            </v-flex>
            <v-flex xs6 style="padding: 0 20px 0 10px">
              <v-select
                v-model="activity.TimeLimitFromType"
                :items="[{
                    id: 'days',
                    name:  $t('booking.activities.days', locale),
                  },
                  {
                    id: 'minutes',
                    name:  $t('booking.activities.minutes', locale),
                  },
                ]"
                itemText="name"
                itemValue="id"
                outlined
                hide-details
              />
            </v-flex>
            <v-flex xs6 style="padding: 10px 10px 0 20px">
              <custom-text-field-number
                v-model="activity.TimeLimitTo"
                :label="$t('booking.activities.timeLimitTo', locale)"
              />
            </v-flex>
            <v-flex xs6 style="padding: 10px 20px 0 10px">
              <v-select
                v-model="activity.TimeLimitToType"
                :items="[{
                    id: 'days',
                    name:  $t('booking.activities.days', locale),
                  },
                  {
                    id: 'minutes',
                    name:  $t('booking.activities.minutes', locale),
                  },
                ]"
                itemText="name"
                itemValue="id"
                outlined
                hide-details
              />
            </v-flex>
          </template>
            <v-flex xs12 style="padding: 10px 20px 20px 20px;">
              <h3>
                {{ $t('booking.activities.quotes', locale) }}
              </h3>
            </v-flex>
            <v-flex xs12 
              style="padding: 0 10px 0 20px"
            >
              <seats-limit
                :editedItem="activity"
                :locale="locale"
                :onChange="handleChangeSeatsLimit"
              />
            </v-flex> 
            <!--v-flex xs12 
              style="padding: 0 10px 0 20px"
            >2
              <custom-text-field-number
                v-model="activity.BookingLimit"
                :label="$t('booking.activities.bookingLimit', locale)"
              />
              <small>L�mite total de reservas de la actividad.</small>
            </v-flex>
            <v-flex xs6 
              style="padding: 0 10px 0 20px"
            >
              <custom-text-field-number
                v-model="activity.Seats"
                :label="$t('booking.activities.seats', locale)"
              />
              <small>L�mite total de plazas de la actividad.</small>
            </v-flex>
            <v-flex xs6 
              style="padding: 0 20px 0 10px"
            >
              <custom-text-field-number
                v-model="activity.SeatsByBooking"
                :label="$t('booking.activities.seatsByBooking', locale)"
              />

              <small>L�mite de plazas por reserva.</small>
            </v-flex>
            <v-flex xs12 
              style="padding:10px 20px 0 20px"
            >
              <center>
                <small>L�mite de <b>{{activity.Seats}}</b> asistentes totales para la actividad. 
                  <br/>M�ximo <b>{{activity.BookingLimit}}</b> reservas de <b>{{activity.SeatsByBooking}}</b> asistentes por reserva.</small>
              </center>
            </v-flex-->
          </template>
        </v-layout>
      </v-card>
      <v-card
        v-else-if="item.form === 'detail' && activity"
        color="grey lighten-1"
        class="mb-12"
        min-height="200px"
      >
        <v-layout wrap>
          <!--v-flex xs12 style="padding:20px 20px 0 20px">
            <translate
              v-model="activity.Name"
              :languages="languages"
              :label="$t('booking.title', locale)"
              @input="handleChangeIsModified"
              type="text"
            />
          </v-flex-->
          <v-flex xs12 style="padding:20px 20px 0 20px">
            <translate
              v-model="activity.Subtitle"
              :languages="languages"
              :label="$t('booking.subtitle', locale)"
              @input="handleChangeIsModified"
              type="text"
            />
          </v-flex>

          <v-flex xs12 style="padding:20px 20px 0 20px">
            <translate
              v-model="activity.Description"
              :languages="languages"
              :label="$t('booking.description', locale)"
              @input="handleChangeIsModified"
              type="richText"
            />
          </v-flex>
          <v-flex v-if="categories" xs12 style="padding:20px 10px 0 10px;">
            <v-autocomplete
              v-model="activity.CategoryID"
              :items="categories"
              outlined
              itemText="Name"
              itemValue="ID"
              hide-details
              :label="$t('booking.category', locale)"
              @change="handleChangeIsModified"
            />
          </v-flex>
          <v-flex xs12 style="padding:20px 10px 0 10px;">
            <color-picker-item
              :value="activity.Color"
              :label="$t('booking.calendarColor', locale)"
              :onChange="handleChangeActivityColor"
            />
            <small>NOTA: El color es solo informativo en la vista calendario, no se muestra al cliente</small>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card
        v-else-if="item.form === 'image' && activity"
        color="grey lighten-1"
        class="mb-12"
        min-height="150px"
      >
        <v-layout wrap>
          <v-flex xs12 style="padding:20px 20px 0 20px">
            <select-image 
              :image="image"
              :onChange="handleChangeImage"
              :locale="locale"
              :imageWidth="800"
              :imageHeight="600"
              :aspectRatio="16/9"
              :folder="workspaceID"
              :limit="1024"
            />
          </v-flex>
        </v-layout>
      </v-card>
      <v-card
        v-else-if="item.form === 'location' && activity"
        color="grey lighten-1"
        class="mb-12"
        min-height="150px"
      >
        <v-layout wrap>
          <v-flex xs12 style="padding:20px 20px 0 20px">
            <place-list-form v-model="activity.PlaceID" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.place', locale)" />
            <!--place-info-form v-model="activity.Place" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.place', locale)" /-->
          </v-flex>
          <v-flex xs12 style="padding:20px 20px 0 20px">
            <place-list-form v-model="activity.MeetingPointID" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.meetingPoint', locale)" />
            <!--place-info-form v-model="activity.MeetingPoint" :locale="locale" @input="handleChangeIsModified" :label="$t('booking.meetingPoint', locale)" /-->
              <br/>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card
        v-else-if="item.form === 'reservation' && activity"
        color="grey lighten-1"
        class="mb-12"
        min-height="60px"
      >
        <v-layout wrap>
          <v-flex xs12 style="padding:0px 20px 0 20px">
            <v-checkbox
              v-model="activity.AllowReservations"
              :label="$t('booking.allowReservations', locale)"
              hide-details
            />
          </v-flex>
          <v-flex xs6 
            v-if="activity.AllowReservations"
            style="padding:20px 10px 0 20px"
          >
            <v-text-field
              outlined
              v-model="activity.Seats"
              :label="$t('booking.activities.seats', locale)"
              hide-details
              type="number"
            />
            <small>L�mite total de plazas de la actividad.</small>
          </v-flex>
          <v-flex xs6 
            v-if="activity.AllowReservations"
            style="padding:20px 20px 0 10px"
          >
            <v-text-field
              outlined
              v-model="activity.SeatsByBooking"
              :label="$t('booking.activities.seatsByBooking', locale)"
              hide-details
              type="number"
            />
            <small>L�mite de plazas por reserva.</small>
          </v-flex>
          <v-flex xs12 
            v-if="activity.AllowReservations"
            style="padding:10px 20px 0 20px"
          >
            <center>
              <small>L�mite de <b>{{activity.Seats}}</b> asistentes totales para la actividad. 
              <br/>M�ximo <b>{{activity.SeatsByBooking}}</b> asistentes por reserva.</small>
            </center>
          </v-flex>
        </v-layout>
      </v-card> 
      <schedule-specific
        v-else-if="item.form === 'schedule' && activity && activity.ScheduleType === 'specific' && schedule"
        :pos="item.pos"
        :schedule="schedule"
        :onChange="handleChangeSchedule"
        :locale="locale"
      />
      <schedule-recurrence-step 
        v-else-if="item.form === 'schedule' && activity && schedule"
        :pos="item.pos"
        :schedule="schedule"
        :onChange="handleChangeSchedule"
        :locale="locale"
        :grayCard="true"
      />
      <form-fields-step
        v-if="item.form === 'formFields' && activity"
        :locale="locale"
        :onChange="handleChangeFormFields"
        :workspaceID="workspaceID"
        :allowReservations="activity.AllowReservations"
      />
      <v-btn 
        v-if="!first || item.pos > 1"
        text 
        @click="handlePrepareChangeStep(-1)" 
        style="margin-right:10px"
      >
        {{$t('common.back', locale)}}
      </v-btn>
      <v-btn
        v-if="last"
        color="primary"
        @click="onSave"
      >
        {{$t('common.save', locale)}}
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="handlePrepareChangeStep(1)"
      >
        {{$t('common.next', locale)}}
      </v-btn>
      <v-btn
        v-if="skip"
        color="primary"
        @click="handleSkip"
        style="margin-left: 10px"
        text
      >
        {{$t('common.skip', locale)}}
      </v-btn>
      <v-btn
        v-if="first && isDevelopment"
        color="red"
        @click="handleTMPSkip"
        style="margin-left: 10px"
        text
      >
        TMP {{$t('common.skip', locale)}} TMP
      </v-btn>


    </v-stepper-content>
  </div>
</template>
<script>
const defaultSchedule = {
  Alias: null,
  Type: 'byWeek',
  Dates: [],
  StartDate: utils.checkDate(new Date()),
  EndDate: null,//utils.checkDate(new Date()),
  Alternation: 1,
  StartTime: 480,
  Interval: 30,
  AllDay: false,
  Duration: 30,
  Months: [],
  Timetable: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  },
  CustomLimit: null,
}
import api from '@/services/api'
import utils from '@/services/utils'
import Translate from '@/components/Translate'
import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
import SeatsLimit from '@/components/seatsLimit/Index'
import SelectImage from '@/components/selectImage/Index'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
//import PlaceInfoForm from '../PlaceInfoForm'
import PlaceListForm from '../PlaceListForm'
import ScheduleRecurrenceStep from '@/components/activityScheduleForm/ScheduleRecurrenceStep'
import ScheduleSpecific from './scheduleSpecific/Index'
import FormFieldsStep from './FormFieldsStep'
import CustomFieldsInfo from '../CustomFieldsInfo'
export default {
  name: 'StepItem',
  components: {
    Translate,
    SelectImage,
    ColorPickerItem,
    SeatsLimit,
    //PlaceInfoForm,
    PlaceListForm,
    ScheduleSpecific,
    ScheduleRecurrenceStep,
    FormFieldsStep,
    CustomTextFieldNumber,
    CustomFieldsInfo,
  },
  props: {
    item: { // step info
      type: Object,
      required: true,
    },
    editedItem: { // activity data
      type: Object,
      required: true,
    },
    step: { // step index
      type: Number,
      required: true,
    },
    first: {
      type: Boolean,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
    skip: {
      type: Boolean,
      default: false,
    },
    pos: { // position actual
      type: Number,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChangeStep: {
      type: Function,
      required: true,
    },
    onChangeEditedItem: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    activity: null,
    image: null,
    schedule: null,
    categories: null,
  }),
  computed: {
    languages () {
      return this.$store.state.app.bookingLanguages
    },
    scheduleTypes () {
      return this.$store.state.app.bookingWorkspaceData.scheduleTypes
    },
    workspaceAllowReservation () {
      return this.$store.state.app.bookingWorkspaceAllowReservation
    },
    workspaceID () {
      return utils.getWorkspace('booking')
    },
    isDevelopment() {
      return process.env.VUE_APP_ENV === 'development'
    },
  },
  watch: {
    editedItem (v) {
      this.activity = v
    },
    pos (v) {
      if (v === 5 && !this.schedule) {
        this.schedule = JSON.parse(JSON.stringify(defaultSchedule))
        this.schedule.Seats = this.activity.Seats
        this.schedule.SeatsByBooking = this.activity.SeatsByBooking
      }
    },
  },
  mounted () {
    this.handleGetCategories()
    this.activity = JSON.parse(JSON.stringify(this.editedItem))
    if (this.scheduleTypes) this.activity.ScheduleType = this.scheduleTypes
  },
  methods: {
    handleGetCategories () {
      api.getAllWithoutLimit('booking', `v1/system/workspaces/${this.workspaceID}/categories`)
        .then(response => {
          this.categories = response ? response.map(item => {
            item.Name = utils.getTranslation(item.Name, this.locale)
            return item
          }) : []
        })
    },
    handlePrepareChangeStep (v) {
      if (this.item.form === 'schedule') this.handleChangeScheduleStep(v)
      else this.handleChangeStep(v)
    },
    handleChangeSchedule (v) {
      this.schedule = v
      this.activity.Schedule = v
    },
    handleChangeFormFields (v) {
      this.activity.FormFields = v
      this.onChangeEditedItem(this.activity)
    },
    handleChangeScheduleStep (v) {
      if (this.activity.ScheduleType !== 'recurrence') this.handleChangeStep(v)
      else if (this.item.pos === 6 && v>0) this.handleChangeStep(v)
      else {
        this.item.pos += v
        if (this.item.pos === 1) {
          this.item.pos = 2
          this.handleChangeStep(v)
        }
      }
    },
    handleChangeStep (v) {
      this.onChangeEditedItem(this.activity)
      this.onChangeStep(v)
    },
    handleTMPSkip () {
      this.item.pos = 1
      this.onChangeStep(+4)
    },
    handleSkip () {
      this.item.pos = 1
      this.onChangeStep(+1)
    },
    handleCancel () {
      this.onClose()
    },

    handleChangeActivityColor (v) {
      this.activity.Color = v
    },
    handleChangeImage (v) {
      if (v) {
        this.image = v.base64
        this.editedItem.newImage = v //'https://social.infoten.es/Fichas/PublishingImages/capture_03112013_152110.jpg'
      }
    },
    handleChangeSeatsLimit (v) {
      this.activity.SeatsType = v.SeatsType
      this.activity.Seats = v.Seats
      this.activity.SeatsByBooking = v.SeatsByBooking
      this.activity.BookingLimit = v.BookingLimit
      this.activity.ResourceID = v.ResourceID
    },
    handleChangeCustomFieldsInfo (v) {
      this.editedItem.CustomFieldsInfo = v
    },
    handleChangeIsModified () {

    },

  } 
}
</script>
<style scope>
.v-stepper__label {
  width:100%;
}
</style>
