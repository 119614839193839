<template>
      <v-card
        v-if="pos && editedItem"
        class="mb-12"
        min-height="150px"
        :color="grayCard ? 'grey lighten-1' : 'white'"
        :style="{ 
                  padding: grayCard ? '10px' : '0 20px',
                  'margin-bottom': grayCard ? '48px' : '20px !important',
                }"
        :elevation="grayCard ? 1 : 0"
      >
        <v-layout wrap v-if="pos === 1">
          <v-flex xs12>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  Tipo
                </v-list-item-title>
                <v-list-item-subtitle>
                  Seleccione el tipo de programaci�n
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12>
            <center>
              <v-btn-toggle
                v-model="editedItem.Type"
                color="primary"
                group
                @change="handleChangeIsModified"
              >
                <v-btn 
                  v-for="scheduleType of scheduleTypes"
                  :key="scheduleType.id"
                  :value="scheduleType.id"
                >
                  {{$t(`booking.scheduleForm.${scheduleType.name}`, locale)}}
                </v-btn>
              </v-btn-toggle>
            </center>
          </v-flex>
          <v-flex xs12>
            <center>
              <small v-html="getScheduleTypeInfo(schedule.Type)" />
            </center>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="pos === 2">
          <v-flex xs12>
            <v-list-item three-line>
              <v-list-item-content>
                <!--div class="text-overline mb-4">
                  OVERLINE
                </div-->
                <v-list-item-title class="text-h5 mb-1">
                  Fechas
                </v-list-item-title>
                <v-list-item-subtitle>
                  Seleccione las fechas de la programaci�n.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12
            v-if="editedItem.Type === 'byDay'"
          >
            <center>
              <v-date-picker
                v-model="editedItem.Dates"
                :min="minDate"
                multiple
                :first-day-of-week="0"
                :locale="locale"
                style="width:100%"
              />
            </center>
          </v-flex>
          <v-flex xs12
            v-else
          >

              <v-layout wrap>
                <v-flex xs1 style="padding-top: 15px">
                </v-flex>
                <v-flex xs11 style="">
                  <custom-datepicker
                    v-model="editedItem.StartDate"
                    :label="'common.startDate'"
                  />
                </v-flex>
                <v-flex xs1 style="padding-top: 15px">
                  <v-checkbox v-model="noEndDate" />
                </v-flex>
                <v-flex v-if="noEndDate" xs11 style="padding-top: 35px;">
                  {{ $t('common.noEndDate', locale) }}
                </v-flex>
                <v-flex xs11 style="padding-top: 15px;"
                    v-else
                >
                  <custom-datepicker
                    v-model="editedItem.EndDate"
                    :label="'common.endDate'"
                  />
                </v-flex>
                <v-flex xs12
                  v-if="editedItem.Type === 'byWeek'"
                  style="padding-top: 5px"
                >
                  <v-text-field
                    :label="$t('common.alternation', locale)"
                    v-model="editedItem.Alternation"
                    outlined
                    hide-details
                    type="number"
                    min="0"
                    step="1"
                  />
                  <small v-if="editedItem.Alternation == 1">La actividad se realiza todas las semanas</small>
                  <small v-else>La actividad se realiza cada {{editedItem.Alternation}} semanas</small>
                  <pax-limit
                    :locale="locale"
                    :onChange="handleChangeCustomLimit"
                    :customLimit="editedItem.CustomLimit"
                  />
                </v-flex>
                <v-flex xs12 style="padding-top: 15px;"
                  v-if="editedItem.Type === 'byMonth'"
                >
                <v-btn-toggle v-model="editedItem.Months" multiple>
                  <v-layout wrap>
                    <v-flex xs4
                      v-for="index of 12"
                      :key="`month${index}`"
                      style="padding: 5px;"
                    >
                      <v-btn color="primary" block>{{ $t(`common.m${index-1}`, locale) }}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-btn-toggle>
                </v-flex>
              </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="pos === 3">
          <v-flex xs12>
            <v-list-item one-line>
              <v-list-item-content>
                <!--div class="text-overline mb-4">
                  OVERLINE
                </div-->
                <v-list-item-title class="text-h5 mb-1">
                  Configuraci�n de horarios
                </v-list-item-title>
                <!--v-list-item-subtitle>
                  Seleccione la configuraci�n inicial de los horarios.
                </v-list-item-subtitle-->
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs1 style="padding-top: 5px">
            <v-checkbox v-model="isUniqueEventByDay" />
          </v-flex>
          <v-flex xs5 style="padding-top: 25px;min-height:100px">
            {{ $t('booking.scheduleForm.isUniqueEventByDay', locale) }}
          </v-flex>
          <v-flex xs6 style="padding-top: 0px;min-height:100px;">
            <interval-times
              v-if="!isUniqueEventByDay"
              :label="`${$t('booking.scheduleForm.interval')}:`"
              v-model="editedItem.Interval"
            />
          </v-flex>
          <v-flex v-if="isUniqueEventByDay" xs12 style="text-align:center">
            <small>La actividad solo se realiza una vez al d�a.</small>
          </v-flex>
          <v-flex v-else xs12 style="text-align: center">
            <small>El intervalo es el tiempo que transcurre entre evento y evento.</small>
            <br/>
            <small><b>Ejemplo</b> la actividad se realiza cada <b>{{convertTimeDetail(editedItem.Interval)}}</b></small>
            <br/><small>(no es determinante, m�s adelante se definir�n los horarios en detalle)</small>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="pos === 4">
          <v-flex xs12>
            <v-list-item one-line>
              <v-list-item-content>
                <!--div class="text-overline mb-4">
                  OVERLINE
                </div-->
                <v-list-item-title class="text-h5 mb-1">
                  Inicio y duraci�n
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs6 style="padding: 0 15px;">
            <interval-times
              :label="`${$t('booking.scheduleForm.startTime')}:`"
              v-model="editedItem.StartTime"
            />
          </v-flex>
          <v-flex xs6 style="padding: 0 15px;">
            <interval-times
              :label="`${$t('booking.scheduleForm.duration')}:`"
              v-model="editedItem.Duration"
            />
          </v-flex>
          <v-flex xs12 style="text-align:center">
            <small>La actividad empieza a las <b>{{convertTime(editedItem.StartTime)}}</b> y dura <b>{{ convertTimeDetail(editedItem.Duration) }}</b></small>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="pos === 5">
          <v-flex xs12>
            <v-list-item one-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  Horario
                </v-list-item-title>
                <v-list-item-subtitle>
                  A�ada las <b>horas</b> de los d�as de la semana en los que se realiza la actividad <b>(+)</b>.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12 style="padding: 0 15px;">
            <time-line
              :editedItem="editedItem"
              :onChange="handleChangeTimetable"
              :isUniqueByDay="isUniqueEventByDay"
            />
          </v-flex>
          <v-flex xs12 style="text-align: right;">
            <small>Al seleccionar una hora puede editarla.</small>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="pos === 6">
          <v-flex xs12>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  Referencia
                </v-list-item-title>
                <v-list-item-subtitle>
                  Indique un nombre de referencia para poder localizarlo.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              :label="$t('booking.scheduleForm.reference', locale)"
              v-model="editedItem.Alias"
              outlined
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-card>
</template>
<script>
import utils from '@/services/utils'
import CustomDatepicker from '@/components/CustomDatepicker'
import IntervalTimes from '@/components/intervalTimes/Index'
import TimeLine from './TimeLine'
import PaxLimit from './PaxLimit'
export default {
  components: {
    CustomDatepicker,
    IntervalTimes,
    TimeLine,
    PaxLimit,
  },
  props: {
    pos: {
      type: Number,
      required: true,
    },
    schedule: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    grayCard: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editedItem: null, 
    noEndDate: true,
    scheduleTypes: [
      {
        id: 'byDay',
        name: 'byDay',
        info: 'infoByDay',
      },
      {
        id: 'byWeek',
        name: 'byWeek',
        info: 'infoByWeek',
      },
      {
        id: 'byMonth',
        name: 'byMonth',
        info: 'infoByMonth',
      },
    ],
    isUniqueEventByDay: false,
  }),
  watch: {
    noEndDate (v) {
      if (v) this.editedItem.EndDate = null
      else this.editedItem.EndDate = utils.checkDate(new Date())
    },
    pos () {
      this.handleChangeIsModified()
    },
  },
  mounted () {
    this.editedItem = this.schedule
  },
  methods: {
    convertTime (v) {
      return utils.convertTime(v).split(' ').join('')
    },
    convertTimeDetail (v) {
      return utils.convertTimeDetail(v)
    },
    getScheduleTypeInfo (v, locale) {
      const aux = this.scheduleTypes.filter(x => x.id === v).shift()
      return aux ? this.$t(`booking.scheduleForm.${aux.info}`, locale) : ''
    },
    handleChangeTimetable (v) {
      this.editedItem.Timetable = v
      this.handleChangeIsModified ()
    },
    handleChangeIsModified () {
      this.onChange(this.editedItem)
    },
    handleChangeCustomLimit (v) {
      this.editedItem.CustomLimit = v
      console.log(JSON.stringify(v))
    },
  },
}
</script>

