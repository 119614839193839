<template>
  <div>
    <v-switch
      v-model="isCustomLimit"
      :label="$t('booking.scheduleForm.customLimit', locale)"
      @change="handleChangeIsModified"
    />
    <seats-limit
      v-if="isCustomLimit"
      :editedItem="customLimit"
      :locale="locale"
      :onChange="handleChangeIsModified"
    />
    <!--div v-if="isCustomLimit">
              <custom-text-field-number
                v-model="bookingLimit"
                :label="$t('booking.activities.bookingLimit', locale).concat(' (2)')"
              />
              <small>L�mite total de reservas de la actividad.</small>
              <custom-text-field-number
                v-model="seats"
                :label="$t('booking.activities.seats', locale).concat(' (10)')"
                style="margin-top:10px"
              />
              <small>L�mite total de plazas de la actividad.</small>
              <custom-text-field-number
                v-model="seatsByBooking"
                :label="$t('booking.activities.seatsByBooking', locale).concat(' (5)')"
                style="margin-top:10px"
              />
              <small>L�mite de plazas por reserva.</small>
    </div-->
  </div>
</template>
<script>
//import CustomTextFieldNumber from '@/components/CustomTextFieldNumber'
import SeatsLimit from '@/components/seatsLimit/Index'
export default {
  components: {
    SeatsLimit,
  //  CustomTextFieldNumber,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
    customLimit: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isCustomLimit: false,
    /*bookingLimit: null,
    seats: null,
    seatsByBooking: null,*/
  }),
  watch: {
    /*
    bookingLimit () {
      this.handleChangeIsModified()
    },
    seats () {
      this.handleChangeIsModified()
    },
    seatsByBooking () {
      this.handleChangeIsModified()
    },*/
    customLimit () {
      this.prepareCustomLimit()
    },
  },
  mounted () {
    this.prepareCustomLimit()
  },
  methods: {
    prepareCustomLimit () {
      this.isCustomLimit = this.customLimit ? true : false
      /*
      if (this.customLimit) {
        this.isCustomLimit = true
        this.bookingLimit = this.customLimit.bookingLimit
        this.seats = this.customLimit.seats
        this.seatsByBooking = this.customLimit.seatsByBooking
      } else {
        this.isCustomLimit = false
        this.bookingLimit = null
        this.seats = null
        this.seatsByBooking = null
      }*/
    },
    handleChangeIsModified (v) {
      if (!this.isCustomLimit) this.onChange(null)
      else this.onChange({
        seatsType: v.SeatsType,
        bookingLimit: v.BookingLimit, 
        seats: v.Seats, 
        seatsByBooking: v.SeatsByBooking
      })
    }
  }
}
</script>

